class Experience {
  constructor(company, start, end, description, image, link) {
    this.company = company;
    this.start = start;
    this.end = end;
    this.description = description;
    this.image = image;
    this.link = link;
  }
}

export default Experience;
